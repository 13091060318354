
import { Options, Vue } from "vue-class-component";
@Options({
  name: "InfoLabel",
  props: {
    title: String,
    value: String,
    bigMode: Boolean,
    desc: String,
  },
})
export default class InfoLabel extends Vue {}
