
import { Options, Vue } from "vue-class-component";
import { provide } from "vue";

@Options({
  created() {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };
    window.addEventListener("resize", appHeight);
    appHeight();
  },
})
export default class App extends Vue {}
