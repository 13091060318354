import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52b5c33e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "button"
}
const _hoisted_2 = {
  key: 0,
  class: "button button_blue",
  "data-testid": "time-value"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrderCarForm = _resolveComponent("OrderCarForm")!

  return (_ctx.completedAndCarIsWaiting)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Машина ожидает"))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (_ctx.ordered)
          ? (_openBlock(), _createElementBlock("button", _hoisted_2, [
              _createElementVNode("span", {
                class: "button__bg",
                style: _normalizeStyle({ width: `${_ctx.percentage}%` })
              }, null, 4),
              (_ctx.remainder > 60)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(" Осталось: " + _toDisplayString(_ctx.getDurationFromSeconds(_ctx.remainder, "minutes")), 1)
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(" Осталось: " + _toDisplayString(_ctx.remainder) + " мин. ", 1)
                  ], 64))
            ]))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.formIsOpen = true))
            }, " Подать автомобиль ")),
        (_ctx.formIsOpen)
          ? (_openBlock(), _createBlock(_component_OrderCarForm, {
              key: 2,
              show: _ctx.formIsOpen,
              "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formIsOpen) = $event))
            }, null, 8, ["show"]))
          : _createCommentVNode("", true)
      ], 64))
}