export const SESSION_STATES = {
  VALET_SESSION_RECEIVED_THE_CAR: 1, // Принял автомобиль
  VALET_SESSION_PARKING_THE_CAR: 2, // Парковка автомобиля
  VALET_SESSION_THE_CAR_IS_PARKED: 3, // Автомобиль припаркован
  VALET_SESSION_REQUESTING_A_CAR_DELIVERY: 4, // Запрошена подача автомобиля
  VALET_SESSION_IN_THE_PROCESS: 5, // В процессе подачи
  VALET_SESSION_THE_PERSON_IN_CHARGE: 6, // Машина ожидает
  VALET_SESSION_THE_CAR_IS_WAITING: 7, // Машина ожидает
  VALET_SESSION_THE_CAR_IS_ISSUED: 8, // Машина выдана
  VALET_SESSION_COMPLETED: 9, // Валет сессия завершена
  VALET_SESSION_COMPLETED_AND_PAID: 10, // Сессия завершена и оплачена
};

export const SESSION_REQUEST_STATES = {
  VALET_REQUEST_ACTIVE: 1, // Ожидает принятия
  VALET_REQUEST_ACCEPTED: 2, // Запрос принят
  VALET_REQUEST_CANCELED: 3, // Запрос отменен
};
