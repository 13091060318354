
import { Options, Vue } from "vue-class-component";
@Options({
  name: "Btn",
  props: {
    color: String,
    fontSize: String,
  },
})
export default class Btn extends Vue {}
