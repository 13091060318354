
import { Options, Vue } from "vue-class-component";
@Options({
  name: "ListItem",
  props: {
    icon: String,
    title: String,
    desc: String,
    bigDesc: Boolean,
  },
})
export default class ListItem extends Vue {}
