
import { Options, Vue } from "vue-class-component";
import Btn from "@/components/Btn.vue";
@Options({
  name: "HelpButton",
  props: {
    text: String,
    color: String,
  },
  components: {
    Btn,
  },
})
export default class HelpButton extends Vue {}
