import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d434d6c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-item" }
const _hoisted_2 = { class: "list-item__icon" }
const _hoisted_3 = {
  width: "12",
  height: "12"
}
const _hoisted_4 = ["xlink:href"]
const _hoisted_5 = { class: "list-item__content" }
const _hoisted_6 = { class: "list-item__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createElementBlock("svg", _hoisted_3, [
        _createElementVNode("use", {
          "xlink:href": `/img/sprite.svg#${_ctx.icon}`
        }, null, 8, _hoisted_4)
      ]))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.title) + ":", 1),
      _createElementVNode("p", {
        class: _normalizeClass(["list-item__desc", { 'list-item__desc_big': _ctx.bigDesc }])
      }, _toDisplayString(_ctx.desc), 3)
    ])
  ]))
}