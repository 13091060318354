import { notify } from "@kyvg/vue3-notification";
import { Api } from "@/api/api";
import { Session } from "@/components/infoblock/interfaces/interfaces";
import moment from "moment";

export async function useBookCar(
  id: string,
  date: moment.Moment
): Promise<Session | boolean> {
  let result = false;

  await Api.bookCar(id, date)
    .then(({ data }) => {
      result = data;
    })
    .catch(({ message }) => {
      notify({
        title: message,
        type: "error",
      });
      notify({
        title: "Ошибка",
        text: "К сожалению на сервере возникла ошибка, пожалуйста обратитесь в поддержку",
        type: "error",
      });
    });

  return result;
}
