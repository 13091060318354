import moment from "moment";

export function getDurationFromSeconds(
  value: number,
  timeType = "seconds"
): string {
  if (value) {
    let output = "";
    const now = moment("2010-10-20");
    const expiration =
      timeType === "seconds"
        ? moment("2010-10-20").seconds(value)
        : moment("2010-10-20").minutes(value);
    const diff = expiration.diff(now);
    const diffDuration = moment.duration(diff);
    if (diffDuration.days()) {
      output += diffDuration.days() + " д. ";
    }
    if (diffDuration.hours()) {
      output += diffDuration.hours() + " ч. ";
    }
    if (diffDuration.minutes()) {
      output += diffDuration.minutes() + " мин.";
    }
    return output;
  }
  return "-";
}
interface Photo {
  type: number;
  img: string;
}
enum PhotoTypes {
  PHOTOS_AT_THE_RECEPTION = 1,
  PHOTOS_FROM_PARKING = 2,
}
export function getSessionCarPhoto(photos: Photo[]) {
  const emptyPhoto =
    "https://images.cars.com/cldstatic/wp-content/uploads/img1820523957-1552665129276.jpg";
  if (photos && photos.length) {
    const photoFromParking = photos.find(
      (i) => i.type === PhotoTypes.PHOTOS_FROM_PARKING
    );

    const photoFromReception = photos.find(
      (i) => i.type === PhotoTypes.PHOTOS_AT_THE_RECEPTION
    );
    return photoFromParking
      ? `${process.env.VUE_APP_PARKPASS_DOMAIN}/api/media${photoFromParking.img}`
      : photoFromReception
      ? `${process.env.VUE_APP_PARKPASS_DOMAIN}/api/media${photoFromReception.img}`
      : emptyPhoto;
  } else {
    return emptyPhoto;
  }
}

export function convertUTCToLocalDate(
  date: string | Date | moment.Moment
): moment.Moment | undefined {
  if (!date) {
    return undefined;
  }
  return moment.utc(date).local();
}
