import { notify } from "@kyvg/vue3-notification";
import { Api } from "@/api/api";
import { Session } from "@/components/infoblock/interfaces/interfaces";

export async function useGetSession(
  parkingId: string | undefined,
  vcid: string | undefined
): Promise<boolean | Session> {
  if (!parkingId || !vcid) {
    notify({
      title: "Не указаны обязательные параметры",
      type: "warn",
    });
    return false;
  }
  let result: false | object = false;
  await Api.getSession(vcid)
    .then(({ data }) => {
      result = data;
    })
    .catch((err) => {
      notify({
        title:
          err.response && err.response.data && err.response.data?.message
            ? err.response.data.message
            : err.message,
        type: "error",
      });
      notify({
        title: "Ошибка",
        text: "К сожалению на сервере возникла ошибка, пожалуйста обратитесь в поддержку",
        type: "error",
      });
    });

  return result;
}
