export interface Parking {
  id: number;
  name: string;
  address: string;
  city?: any;
  picture?: string;
}
export interface Request {
  id: number;
  car_delivery_time: string;
  status: number;
  created_at: string;
}
export interface Session {
  id: number;
  client_id: string;
  state: number;
  parking: Parking;
  car_number: string;
  car_color: string;
  car_model: string;
  car_photo: string;
  debt: string;
  valet_card_id: string;
  parking_card: string;
  parking_floor: number;
  parking_space_number: string;
  parking_place: string;
  created_by_user: number;
  responsible: number;
  parking_card_get_at?: any;
  car_delivery_time?: any;
  duration: number;
  car_delivered_at?: any;
  car_delivered_by?: any;
  paid_at?: any;
  parking_card_session: number;
  comment: string;
  started_at: string;
  request: Request;
}

export function isSession(obj: any): obj is Session {
  return typeof obj !== "boolean" && "id" in obj && "debt" in obj;
}
