
import TopBackground from "@/components/TopBackground.vue";
import { Options, Vue } from "vue-class-component";
import InfoBlock from "@/components/infoblock/InfoBlock.vue";

@Options({
  components: {
    TopBackground,
    InfoBlock,
  },
})
export default class HomeView extends Vue {}
