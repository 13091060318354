import axios, { AxiosResponse, AxiosStatic } from "axios";
import moment from "moment";

export const Api = {
  getSession(id: string): Promise<AxiosResponse> {
    return axios.get(
      `${process.env.VUE_APP_PARKPASS_DOMAIN}/api/v1/valet/session/get/`,
      { params: { id } }
    );
  },
  getSessionStatus(id: string): Promise<AxiosResponse> {
    return axios.get(
      `${process.env.VUE_APP_PARKPASS_DOMAIN}/api/v1/valet/session/status/`,
      { params: { id } }
    );
  },
  bookCar(id: string, date: moment.Moment): Promise<AxiosResponse> {
    return axios.post(
      `${process.env.VUE_APP_PARKPASS_DOMAIN}/api/v1/valet/session/book/`,
      { id, date: date.format("YYYY-MM-DD HH:mm") }
    );
  },
};
