
import { Options, Vue } from "vue-class-component";
import OrderCarForm from "@/components/OrderCarForm.vue";
import moment from "moment";
import { getDurationFromSeconds } from "@/helpers/helpers";
@Options({
  name: "OrderCarButton",
  components: {
    OrderCarForm,
  },
  props: {
    ordered: Boolean,
    completedAndCarIsWaiting: Boolean,
    minutesLeft: Number,
    minutesPast: Number,
    secondsPast: Number,
  },
  data() {
    return {
      formIsOpen: false,
    };
  },
  computed: {
    percentage() {
      return (this.secondsPast * 100) / (this.minutesLeft * 60);
    },
    remainder() {
      const sum = this.minutesLeft - this.minutesPast;
      return sum > 0 ? sum : 0;
    },
  },
  methods: {
    getDurationFromSeconds(val: number, type: string) {
      return getDurationFromSeconds(val, type);
    },
  },
})
export default class OrderCarButton extends Vue {}
