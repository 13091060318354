import { notify } from "@kyvg/vue3-notification";
import { Api } from "@/api/api";
import { Session } from "@/components/infoblock/interfaces/interfaces";
import moment from "moment";

export async function useGetSessionStatus(
  id: string
): Promise<number | undefined> {
  let result;
  await Api.getSessionStatus(id)
    .then(({ data }) => {
      result = data.session_status;
    })
    .catch(({ message }) => {
      notify({
        title: "Ошибка",
        text: "Не удалось получить статус сессии",
        type: "warn",
      });
    });

  return result;
}
